import React, { useEffect, useState } from 'react'
import { Button, CardText, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import { selectStyle, selectThemeColors } from '../../modules/commonModel/Utils'
import { JobLegsService } from '../../modules/Services/JobLegs'
import toast from 'react-hot-toast'
import { Controller, useForm } from 'react-hook-form'
import { LocationService } from '../../modules/Services/Location'
import AsyncSelect from 'react-select/async'

function SmsModel({
    openSmsModel=false,
    setOpenSmsModel,
    statusData,
    changeCheckbox
}) {
    const { control, handleSubmit, clearErrors, formState: { errors } } = useForm()
    // const [currentStatus, setCurrentStatus] = useState({ value: '', label: 'Select Status' })
    const [notifyData, setNotifyData] = useState([])
    const [betweenDates, setBetweenDates] = useState<any>()
    const [isSendSms, setIsSendSms] = useState(true)
    const [isSendMail, setIsSendMail] = useState(true)



    // const [currentFrom, setCurrentFrom] = useState({ value: '', label: 'Select From  Location' })
    // const [currentTo, setCurrentTo] = useState({ value: '', label: 'Select To Location' })
    const [loader, setLoader] = useState(false)
    const entity_id = localStorage.getItem('entity_id')
    // let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;

    // const loadLocationOptions = (inputValue: any, callback: any) => {
    //     if (locationTimeoutId) {
    //       clearTimeout(locationTimeoutId);
    //     }
    //     if (inputValue?.length > 3) {
    //         locationTimeoutId = setTimeout(() => {
    //         const obj = { 
    //             getall: 1,
    //             fields: 'id,location_name',
    //             location_name : inputValue
    //         }
    //         LocationService.getLocationList(entity_id, obj)
    //         .then((res: any) => {
    //             callback(res?.data?.response?.map((item: any) => {
    //             return { value: item?.id, label: item?.location_name }
    //             }))
    //         })
    //         .catch((err: any) => console.log(err?.response?.data?.message))
    //         }, 1000)
    //     }
    // }

    function fetchFormData() {
        setLoader(true)
        // const data = {
        //     booking_from: currentFrom?.value,
        //     booking_to: currentTo?.value,
        //     booking_status: currentStatus?.value
        // }
        const data = {
            job_ids: changeCheckbox,
            isSendSms:isSendSms,
            isSendMail:isSendMail
        }
        JobLegsService.sendSms(entity_id, data)
        .then((res: any) => {
            setOpenSmsModel(false)
            toast.success(res?.data?.message)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }
    
    // useEffect(() => {
    //     if (!openSmsModel) {
    //         setCurrentFrom({ value: '', label: 'Select From Location' })
    //         setCurrentTo({ value: '', label: 'Select To Location' })
    //         setCurrentStatus({ value: '', label: 'Select Status' })
    //         clearErrors()
    //     }
    // }, [openSmsModel])

    function getJobDetail() {
        setLoader(true)

        const data = {
            job_ids: changeCheckbox
        }
        JobLegsService.getNotifyDeiverDetail(entity_id, data)
        .then((res: any) => {
            setNotifyData(res?.data?.driversList)
            setBetweenDates(res?.data?.dates)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))
    }

    useEffect(() => {
        if (openSmsModel) {
            getJobDetail()
        }
    }, [openSmsModel])
    
  return (
    <Modal isOpen={openSmsModel} toggle={() => setOpenSmsModel(false)} className='modal-dialog-centered model-lg smsModel' >
        <ModalHeader className='bg-transparent' toggle={() => { setOpenSmsModel(false) }}>
            <CardText >SMS Notifications for {betweenDates ? betweenDates?.min_date + ' to ' + betweenDates?.max_date : null}</CardText>
        </ModalHeader>
        <Form onSubmit={handleSubmit(fetchFormData)}>
            <ModalBody>
                <Row>
                    {notifyData?.map((item) => {
                        return (
                            <div key={item?.job_id} className='mb-5'>
                                <span>
                                    <strong>{item?.driver_name}</strong> 
                                    <span className='ms-2'>({item?.driver_mobile ? (item?.driver_mobile?.length > 3 ? ("+" + item?.driver_mobile) : '') : ''})</span>
                                </span>
                                {Object.entries(item?.dates).map(([date, jobs]: any) => {
                                    return (
                                        <div key={date}>
                                            <span>{date}</span>
                                            {jobs?.map((job: any, index: any) => (
                                                <div key={index}>
                                                    <span>{job.vehicle}{job.pickup_time ? ',' : ''} {job.pickup_time}{job.pickup_location ? ',' : ''} {job.pickup_location} to {job.dropoff_location}{job.notes ? ',' : ''} {job.notes || ""}</span>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Row>
                {/* <Row>
                    <Col md={6} className="mb-5">
                        <Label className='form-label' for='from'>From</Label>
                        <Controller name='from' control={control} rules={{required : true}}
                            render={({ field: { onChange } }) => (
                                <AsyncSelect
                                    isClearable={true}
                                    className='react-select w-100'
                                    classNamePrefix='select'
                                    placeholder='Search From'
                                    isRtl={false}
                                    name='callback-react-select'
                                    loadOptions={loadLocationOptions}
                                    value={currentFrom}
                                    cacheOptions
                                    onChange={(data: any) => {
                                        onChange(data)
                                        setCurrentFrom(data)
                                    }}
                                    noOptionsMessage={() => 'No options'}
                                />
                            )}
                        />
                        {errors?.from && (<small className="text-danger ml-2">From Date is a required field</small>)}
                    </Col>
                    <Col md={6} className="col-6">
                        <Label className='form-label' for='to'>To</Label>
                        <Controller name='to' control={control} defaultValue='' rules={{required : true}}
                            render={({ field: {onChange} }) => (
                                <AsyncSelect
                                    isClearable={true}
                                    className='react-select w-100'
                                    classNamePrefix='select'
                                    placeholder='Search To'
                                    isRtl={false}
                                    name='callback-react-select'
                                    loadOptions={loadLocationOptions}
                                    value={currentTo}
                                    cacheOptions
                                    onChange={(data: any) => {
                                        onChange(data)
                                        setCurrentTo(data)
                                    }}
                                    noOptionsMessage={() => 'No options'}
                                />
                            )}
                        />
                        {errors?.to && (<small className="text-danger ml-2">To Date is a required field</small>)}
                    </Col>
                    <Col md={6} className="col-6">
                        <Label className='form-label' for='to'>Status</Label>
                        <Controller name='status' control={control} defaultValue='' rules={{ required : true }}
                            render={({ field: {onChange} }) => (
                                <ReactSelect
                                    isClearable={false}
                                    className='react-select'
                                    classNamePrefix='select'
                                    theme={selectThemeColors}
                                    styles={errors.status && selectStyle}
                                    options={statusData}
                                    value={currentStatus}
                                    onChange={(data: any) => {
                                        onChange(data)
                                        setCurrentStatus(data)
                                    }}
                                />
                            )}
                        />
                        {errors?.status && (<small className="text-danger ml-2">Status is a required field</small>)}
                    </Col>
                </Row> */}
            </ModalBody>
            <ModalFooter style={{border : "none"}}>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                    <div className="d-flex align-items-center me-4">
                        <Input type="checkbox" className="me-2" checked={isSendSms} onChange={(e) => setIsSendSms(e.target.checked)} />
                        <label className="mb-0">SMS</label>
                    </div>
                    <div className="d-flex align-items-center">
                        <Input type="checkbox" className="me-2" checked={isSendMail} onChange={(e) => setIsSendMail(e.target.checked)} />
                        <label className="mb-0">EMAIL</label>
                    </div>
                </div>
                <div className='d-flex'>
                    <Button color="secondary" onClick={() => setOpenSmsModel(false)}>Cancel</Button>
                    <Button color="primary" type='submit' className="ms-2" disabled={loader} >
                        {loader ? <Spinner size='sm' /> : 'Send'}
                    </Button>
                </div>
            </div>
            </ModalFooter>
        </Form>
    </Modal>
  )
}

export default SmsModel