import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import CustomersPageWrapper from '../pages/Customers/CustomersPageWrapper'
import { AddCustomer } from '../pages/Customers/AddCustomer'
import { LocationPage } from '../pages/Locations/LocationPage'
import { AddLocation } from '../pages/Locations/AddLocation'
import { JobPage } from '../pages/Jobs/JobPage'
import { AddJob } from '../pages/Jobs/AddJob'
import { VehiclePage } from '../pages/Vehicles/VehiclePage'
import { AddVehicle } from '../pages/Vehicles/AddVehicle'
import { DriverPage } from '../pages/Driver/DriverPage'
import { AddDriver } from '../pages/Driver/AddDriver'
import { JobLegsPage } from '../pages/JobLegs/JobLegsPage'
import { AddJobLeg } from '../pages/JobLegs/AddJobLeg'
import ResourceBoard from '../pages/resourceboard/ResourceBoard'
import InvoicePage from '../pages/Invoices/InvoicePage'
import TagPage from '../pages/Tags/TagPage'
import AddTags from '../pages/Tags/AddTags'
import ProfilePage from '../pages/Profile'
import SettingPage from '../pages/Settings'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to planner after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/planner' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}

        <Route path='customers' element={<CustomersPageWrapper />} />
        <Route path='customers/add' element={<AddCustomer customerSearch={null} searchBy={null} setSearchBy={() => {}} setOpenCustomerModel={() => {}} setCustomerName={() => {}} />} />
        <Route path='customers/:customer_id/edit' element={<AddCustomer customerSearch={null} searchBy={null} setSearchBy={() => {}} setOpenCustomerModel={() => {}} setCustomerName={() => {}} />} />

        <Route path='location' element={<LocationPage />} />
        <Route path='location/add' element={<AddLocation setNewLocationData={undefined} />} />
        <Route path='location/:location_id/edit' element={<AddLocation setNewLocationData={undefined} />} />

        <Route path='booking' element={<JobPage />} />
        <Route path='booking/add' element={<AddJob />} />
        <Route path='booking/:id/edit' element={<AddJob />} />

        <Route path='vehicle' element={<VehiclePage />} />
        <Route path='vehicle/add' element={<AddVehicle />} />
        <Route path='vehicle/:id/edit' element={<AddVehicle />} />

        <Route path='driver' element={<DriverPage />} />
        <Route path='driver/add' element={<AddDriver />} />
        <Route path='driver/:id/edit' element={<AddDriver />} />
        
        <Route path='jobs' element={<JobLegsPage />} />
        <Route path='jobs/add' element={<AddJobLeg />} />
        <Route path='jobs/:id/edit' element={<AddJobLeg />} />
        
        {/* Resource Board  */}
        <Route path='planner' element={<ResourceBoard />} />
        
        <Route path='invoice' element={<InvoicePage />} />

        <Route path='tags' element={<TagPage />} />
        <Route path='tags/add' element={<AddTags />} />
        <Route path='tags/:tag_id/edit' element={<AddTags />} />

        <Route path='profile' element={<ProfilePage />} />

        <Route path='settings' element={<SettingPage />} />
        
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
