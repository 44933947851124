import React, { Fragment, useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon } from '../../../_metronic/helpers'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import toast from 'react-hot-toast'
import { InvoiceService } from '../../modules/Services/Invoice'
import InvoiceModel from './InvoiceModel'
import UILoader from '../../modules/commonModel/Ui-loader'
import { Table } from 'reactstrap'

function InvoicePage() {
    const entity_id = localStorage.getItem('entity_id')
    const [pending, setPending] = useState(false)
    const [invoiceData, setInvoiceData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [invoiceId, setInvoiceId] = useState(false)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)

    const getInvoice = () => {
        setPending(true)
        InvoiceService.getInvoiceList(entity_id, {
            per_page: 20,
            page: currentPage
        })
        .then((res: any) => { 
            if (currentPage > 1) setInvoiceData(pre => [...pre, ...res?.data?.response?.data]) 
            else setInvoiceData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
        })
        .catch((err: any) => {
            console.log(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    function downloadInvoicePdf(invoice_id: any) {
        InvoiceService.downloadInvoicePdf(entity_id, invoice_id)
        .then((res: any) => { 
            toast.success(res?.data?.message)
            window.open(res?.data?.url, '_blank', 'noopener,noreferrer') 
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getInvoice()
        }
    }, [renderElementService])
    
    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

  return (
    <Fragment>
        <InvoiceModel 
            openInvoice={openInvoice}
            setOpenInvoice={setOpenInvoice}
            invoiceId={invoiceId}
            setInvoiceId={setInvoiceId}
            setRenderElementService={setRenderElementService}
            bookingData={null}
        />
        <KTCard>
            <KTCardBody className='p-0 invoice_table'>
                <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                    <div onScroll={(e) => handleScroll(e)} id='booking_table' className='invoice-table-style'>
                        <Table className='jobs-table'>
                            <thead className='sticky-header' style={{zIndex: '2'}}>
                                <tr style={{fontWeight: '500'}}>
                                    <th className='ps-3'>Invoice Number</th>
                                    <th>Clients</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th className='text-end pe-3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData?.map((item: any, i: any) => {
                                    return (
                                        <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                                            <td className='ps-3'>
                                                <span className='fw-bold cursor-pointer' onClick={() => { setInvoiceId(item?.id); setOpenInvoice(true) }}>
                                                    {item?.invoice_number}
                                                </span>
                                            </td>
                                            <td>
                                                <div>{item?.customer_name}</div>
                                            </td>
                                            <td>
                                                <div>{item?.date ? moment(item?.date).format('DD-MM-YYYY') : ''} </div>
                                            </td>
                                            <td>
                                                <div>{item?.amount}</div>
                                            </td>
                                            <td className='pe-3 py-0'>
                                                <div className='d-flex justify-content-end flex-shrink-0 pt-2'>
                                                    <a onClick={() => downloadInvoicePdf(item?.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                                        <KTIcon iconName='folder-down' className='fs-3' />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {invoiceData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no invoices to display</p>
                            : ''
                        }
                    </div>
                </UILoader>
            </KTCardBody>
        </KTCard>
    </Fragment>
  )
}

export default InvoicePage